import 'jquery-sparkline/jquery.sparkline.js';

export var Sparkline = {
  refreshSparklines: function() {
    $('.sparkline').each( function(index, element) {
      if (!$(element).data('sparkline-enabled')) {
        $(element).sparkline('html', Sparkline.options($(element)));
        $(element).data('sparkline-enabled', true);
      }
    });
  },

  options: function(element) {
    var options = {
                    type: 'bar',
                    barWidth: 1,
                    barSpacing: 0,
                    barColor: '#E45F00',  //TODO: Pull colors from main?
                    negBarColor: '#949870',
                    highlightColor: '#000000'
                  };
    switch ($(element).data('sparkline-type')) {
      case 'quarterly':
        options['tooltipFormatter'] = Sparkline.quarterlyTooltipFormatter;
        options['units'] = $(element).data('sparkline-units');
        options['xOffset'] = $(element).data('sparkline-x-offset');
        break;
    }
    return options;
  },

  quarterlyTooltipFormatter: function(sparklines, options, fields) {
    var value = fields[0].value;
    var offset = fields[0].offset;
    var xOffset = options.get('xOffset', 0);
    var units = options.get('units', '');
    if (value === 0) {
      return "Q" + (offset % 4 + 1) + " " + (Math.floor(offset / 4) + xOffset);
    } else {
      return value.toLocaleString() + " " + units + " in Q" + (offset % 4 + 1) + " " + (Math.floor(offset / 4) + xOffset);
    }
  },
};
